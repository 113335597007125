:root {
  /* Fixes the transition on /book for subjects */
  overflow-y: hidden;
}

body {
  /* background-color: #282c34; */
  /*Color Globals*/
  --primary: #fcfaf9;
  /*#282c34*/
  --secondary-light: #99ffd7;
  --secondary: #00cc7c;
  --secondary-dark: #00613b;
  --joker: #9ba2ff;

  --accept-primary: #42ba96;
  --accept-secondary: #fff;
  --reject-primary: #df4759;
  --reject-secondary: #fff;

  --black: #000;
  --alm-black: #2a2a2a;
  --darker: #c1c1c1;
  --dark: #959595;
  --alm-light: #e5e5e5;
  --light: #fff;
  /*Border radius*/
  --rad-sm: 5px;
  --rad-md: 10px;
  --rad-lg: 20px;
  --rad-xl: 50px;
  /*Border thickness*/
  --thin: 1px;
  --thick-md: 2px;
  --thick: 5px;
  /*Font size*/
  --f1: 2.5rem;
  --f2: 2rem;
  --f3: 1.25rem;
  --f4: 1rem;
  --f5: 0.75rem;
  --f6: 0.5rem;
  /* Font Weights */
  --w1: 500;
  --w2: 300;
  --w3: 200;
  /*Sizes*/
  /*widths*/
  --mini-w: 10vw;
  --small-w: 20vw;
  --med-w: 50vw;
  --large-w: 80vw;
  /*heights*/
  --min-h: 2rem;
  --thin-h: 6rem;
  --med-h: 10rem;
  --tall-h: 50vh;
  --giant-h: 80vh;
  /*Other Globals*/
  --w: 100vw;
  --h: 100vh;
  /*Defaults*/
  margin: 0;
  padding: 0;
  /* hide scrollbar for IE, Edge and Firefox*/
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /*No idea*/
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Font */
  font-family: "Padauk", sans-serif;
  font-size: var(--f4);
  font-weight: var(--w3);

  background-color: var(--primary);
  color: var(--dark);
  min-height: 100vh !important;
}

* {
  max-width: 100vw;
}

*:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

/*Remove annoying defaults*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  border: none;
  box-shadow: none;
}

a {
  color: inherit;
  text-decoration: none;
}

a:visited {
  color: inherit;
  text-decoration: none;
}

input,
textarea,
label {
  color: inherit;
  background-color: inherit;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
}

::placeholder {
  color: inherit;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

.btn-default {
  background-color: var(--secondary) !important;
  color: var(--light) !important;
  border-radius: var(--rad-md);
}

.btn-danger {
  background-color: var(--reject-primary) !important;
  color: var(--light) !important;
  border-radius: var(--rad-md);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.md-form .prefix~input,
.md-form .prefix~textarea {
  width: calc(100% - 5rem) !important;
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

/*Common classes*/
.f1 {
  font-size: var(--f1) !important;
  font-weight: var(--w1) !important;
  color: var(--black);
}

.f2 {
  font-size: var(--f2) !important;
  font-weight: var(--w2) !important;
  color: var(--black);
}

.f3 {
  font-size: var(--f3) !important;
  font-weight: var(--w3) !important;
}

.f4 {
  font-size: var(--f4) !important;
  font-weight: var(--w3) !important;
}

.f5 {
  font-size: var(--f5) !important;
  font-weight: var(--w3) !important;
}

.f6 {
  font-size: var(--f6) !important;
  font-weight: var(--w3) !important;
}

.w1 {
  font-weight: var(--w1) !important;
}

.w2 {
  font-weight: var(--w2) !important;
}

.w3 {
  font-weight: var(--w3) !important;
}

.cap {
  text-transform: capitalize;
}

.black {
  color: var(--black) !important;
}

.alm-black {
  color: var(--alm-black) !important;
}

.darker {
  color: var(--darker) !important;
}

.dark {
  color: var(--dark) !important;
}

.alm-light {
  color: var(--alm-light) !important;
}

.light {
  color: var(--light) !important;
}

.secondary-light {
  color: var(--secondary-light) !important;
}

.secondary {
  color: var(--secondary) !important;
}

.secondary-dark {
  color: var(--secondary-dark) !important;
}

.round-img-parent {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}

.round-img-parent .lying-img {
  width: auto;
  height: 100%;
  transform: translate(-25%, 0);
}

.round-img-parent .standing-img {
  width: 100%;
  height: auto;
  transform: translate(-25%, 0);
}

.accept-theme {
  background-color: var(--accept-primary) !important;
  color: var(--accept-secondary) !important;
}

.reject-theme {
  background-color: var(--reject-primary) !important;
  color: var(--reject-secondary) !important;
}

.big-info {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  font-size: var(--f1);
  text-align: center;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.fullscreen-container {
  height: 100vh;
}