.selector_parent {
    border-radius: var(--rad-md);
    overflow: hidden;
}
.selector {
    background-color: var(--dark);
    color: var(--light);
}
.selector > * {
    color: inherit;
}
.selector.chosen {
    background-color: var(--light);
    color: var(--dark);
}