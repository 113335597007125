.cont {
  width: 100vw;
  height: 200px;
  background-color: #f00;
}

.swipeCard {
}

.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
}
