
.animation {
    width: 100vw;
    height: 100vh;
    background-color: white;
    text-align: center; 
}

.animation_header {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: var(--f1);
    color: orange;
    position:absolute;
    animation: zoom-out-out 1s ease-out;
}

@keyframes zoom-out-out {
    0% {
        transform:scale(50,50);
    }
    100% {
        transform: scale(0,0);
    }
}

.animation2 {
    width:100vw;
    height: 100vh;
    background-color:white;
    text-align:center;
}

.animation_header2 {
    animation-delay: 10s;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: var(--f1);
    color: orange;
    animation: zoom-out-out-delay 1s ease-out;
}

@keyframes zoom-out-out-delay {
    0% {
        transform:scale(400,400);
    }
    50%{
        transform: scale(150,150)
    }
    100% {
        transform: scale(1,1);
    }
}

.logo_fade {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    animation: emerge 1s;
}

@keyframes emerge {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}















/* .wrapper{
    display:flex;
    width:100%;
    height 100%;
    transition: margi .5s;
    margin: 0 0 0 -250px
}

.wrapper.is-nav-open{
    margin-left:0;
}

.nav{
    position: relative;
    width:250px;
    height:100%;
    padding:20px;
    border-right:1px solid #ccc;
}

.nav__icon {
    position: absolute;
    top:0;
    right:-60px;
    padding:20px;
    font-size:20px;
    transition: color .3s;
    flex-shrink: 0;
}
*/