.errMsg {
  position: fixed;
  top: 5%;
  right: 15%;
}

.Login_logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Login_logo {
    animation: Login-logo-spin infinite 20s linear;
  }
}

.Login_header {
  background-color: var(--primary);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sign_up_link {
  color: var(--secondary) !important;
  /* text-decoration: underline !important; */
}

@keyframes Login-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
