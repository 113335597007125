.saved_loc_parent {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0 8px 1px 8px;
}

.saved_loc {
  border-radius: var(--rad-md) !important;
  height: var(--min-h) !important;
  margin: 0 3px;
  padding: 0.8rem;
}

.saved_loc_button {
  background-color: var(--secondary-dark);
  color: var(--secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-weight: 500;
  transition: opacity 0.2s;
  border: none;
}

.loading_placeholder {
  animation: opacity_enter 500ms;
}

@keyframes opacity_enter {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.nxt_btn_parent {
  position: absolute;
  bottom: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.nxt_btn {
  background-color: var(--secondary) !important;
  color: var(--light) !important;
  border-radius: var(--rad-lg) !important;
  z-index: 5;
}

/* position_select_info */