/*  Necessary style  */
.confirm_section {
}
.title {
  color: var(--secondary-dark);
}
.group_1 {
  /* background-color: var(--secondary);
  color: var(--primary); */
  /* border-radius: var(--rad-md); */
}
.group_1 hr {
  height: var(--thick-md);
}
.info {
  color: var(--alm-black);
  font-weight: var(--w2);
  overflow-x: scroll;
}
.info span {
  /* border-bottom: var(--thick-md) solid var(--joker); */
}

.group_2 {
  width: 100%;
  min-height: 0;
  background-color: var(--alm-light);
  padding-right: 0;
  padding-left: 0;
}
.group_2 textarea {
  background-color: var(--darker);
  color: var(--alm-black);
  border-radius: var(--rad-md);
  width: 100%;
}
.group_2 textarea:focus {
  border: none !important;
}
.group_2 hr {
  height: var(--thick-md);
  color: var(--alm-black);
}
.green_txt {
  color: var(--secondary-dark);
}
.confirm_btn {
  background-color: var(--secondary) !important;
  color: var(--secondary-dark) !important;
  width: 100%;
  border-radius: var(--rad-md) !important;
}
.filler {
  width: 100%;
  height: 100vh;
}
/*.group_1 .list_item {
    border-top: var(--thin) solid var(--joker);
    border-left:  var(--thin) solid var(--joker);
    border-right: var(--thin) solid var(--joker);
}
.group_1 .list_item:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.group_1 .list_item:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 1px solid grey;
}
.group_1 .list_item > div {
    align-items: flex-start;
    justify-content: space-between;
    display: flex;
    padding: 20px 16px;
}
.group_1 > .list_item > div > div:nth-child(odd) {
    flex-grow: 1;
    flex-basis: 90px;
    max-width: 50%;
    padding-right: 12px;
}
.group_1 > .list_item > div > div:nth-child(even) {
    display: flex;
    word-break: break-word;
    align-items: center;
    align-self: flex-start;
    flex: 1 0;
}*/
