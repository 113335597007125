.react-datepicker-wrapper input:focus {
  outline: none;
  caret-color: transparent;
}

.startTimeWrapper,
.lessonLengthWrapper {
  background-color: #e5e5e5 !important;
  padding: 15px !important;
  border-radius: 10px !important;
  display: flex !important;
  align-items: center !important;
}

.lessonLengthWrapper {
  padding: 15px 35px !important;
  align-items: flex-end !important;
}

.startTimeWrapper>* {
  margin: 0 3px !important;
}

.lessonLengthWrapper>* {
  margin: 0 2px !important;
}

.startTimeText,
.lessonLengthText {
  color: #595959 !important;
}

.lessonLengthText {
  font-size: 1.2rem !important;
}

.goToNextButton {
  background-color: #01CC7B !important;
  border-radius: 20px !important;
  padding: 9px 40px !important;
  font-size: 1rem !important;
  color: #01613B !important;
  margin: 2rem auto 1.5rem !important;
  display: block !important;
}

/* Chrome, Safari, Edge, Opera */
.numericInput input::-webkit-outer-spin-button,
.numericInput input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
.numericInput input[type="number"] {
  -moz-appearance: textfield !important;
}