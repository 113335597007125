.small {
    background-color: var(--secondary);
    color: var(--primary);
    padding: 1rem;
    border-bottom: var(--thick-md) solid var(--primary);
}
.fullInfo {
    position: relative;
    background-color: var(--primary);
    color: var(--secondary);
    border: var(--thick-md) solid var(--accept-primary);
    border-radius: var(--rad-md);
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
}
.minimizer {
    color: var(--reject-primary);
    position: absolute;
    left: 2%;
    top: 2%;
}
.fullInfo .info {
    background-color: var(--secondary);
    color: var(--primary);
    padding: 1rem;
}
.arrivalInp {
    width: var(--small-w);
}